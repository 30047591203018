<template>
  <div id="app">
    <div v-if="showLogin">
      <!-- Muestra la ventana de inicio de sesión -->
      <LoginView @loggedIn="onLoggedIn" />
    </div>
    <div v-else>
      <!-- Muestra el menú y otras partes de la aplicación -->
      <nav class="navbar navbar-expand-lg navbar-dark bg-success">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">BiPracticoERP</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" to="/inicio" aria-current="page">Inicio</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/proyecto">Proyecto</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/consultapresupuesto">Consulta de Presupuesto</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/nconsultapresupuesto">Presupuesto</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/etapasyactividadespresupuesto">Etapas y Actividades</router-link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Configuración
                </a>
                <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" to="/detalleproyecto">Crear Proyecto</router-link></li>
                  <li><router-link class="dropdown-item" to="/importarpresupuesto">Importar Presupuesto</router-link></li>
                  <li><router-link class="dropdown-item" to="/elementospresupuesto">Elementos</router-link></li>
                  <li><router-link class="dropdown-item" to="/etapasyactividadespresupuesto">Etapas y
                      Actividades</router-link></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><router-link class="dropdown-item" to="/opcionespresupuesto">Opciones</router-link></li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled">Programa de obra</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container-fluid">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  import LoginView from "@/views/seguridad/LoginView.vue";

  export default {
    components: {
      LoginView,
    },

    data() {
      return {
        showLogin: true,
      };
    },

    methods: {
      onLoggedIn() {
        this.showLogin = false;
      },
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /*color: #2c3e50;*/
  }

  nav {
    padding: 30px;
    background-color: gray;
    color: rgb(128, 59, 59);  
  }

  nav a {
    font-weight: bold;
    color: rgb(121, 151, 196);
    /*color: #2c3e50;*/
  }

  nav a.router-link-exact-active {
    color: #52565e;
  }

  .container-fluid>.router-view {
    color: rgb(180, 200, 230);
  }
</style>
