<template>
  <div>
    <h1>Importar presupuesto desde Excel a Practico</h1>
    <div class="form-group">
      <label for="projectSelect">Seleccionar proyecto:</label>
      <select class="form-select" aria-label="Default select example" v-model="selectedProject" id="projectSelect">
        <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.proyecto }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="fileInput">Selecciona el archivo xlsx a importar:</label>
    </div>
    <div class="form-group">
      <input type="file" @change="handleFileUpload" />
    </div>
    <div class="form-group">
      <button @click="uploadFile">Subir archivo</button>
    </div>
    <div class="form-group">
      <p v-if="statusMessage">{{ statusMessage }}</p>
    </div>
  </div>
</template>
  
<script>
//import axios from "axios";
export default {
  data() {
    return {
      selectedProject: null,
      tempprojects: null,
      projects: null, //[], // Debes llenar esta lista con los proyectos disponibles desde tu API
      editMsg: '&nbsp;',
      file: null,
      statusMessage: "",
    };
  },
  async mounted() {
    const customStyle = document.createElement('style');
    customStyle.innerHTML = `
      .wj-cell.wj-alt {
        height: auto !important;
        /* Otros estilos si es necesario */
      }
    `;
    document.head.appendChild(customStyle);
    try {
      await this.getProject(); // Llama a la función para obtener la lista de proyectos
      //await this.fetchData();
    } catch (error) {
      console.error('Error al obtener los proyectos: ', error);
    }
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      this.statusMessage = "Espere, esta realizandose la importación.";
      if (!this.file) {
        this.statusMessage = "Por favor, seleccione un archivo Excel.";
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);
      //Se agrega en formData el proyecto, el usuario y el cliente.
      formData.append('proyecto', this.selectedProject);
      formData.append('usuario', 1);
      formData.append('cliente', 3);

      console.log(this.file.path);
      console.log(this.selectedProject);
      console.log(this.usuario);
      console.log(this.cliente);
      //const file = req.file;

       try {

        //Se omite en envio del proyecto en la url ya que ahora va en el body
        //const response = await fetch(`http://157.245.220.201:3002/api/presupuestos/importar-presupuesto/${this.selectedProject}`, {
        const response = await fetch(`http://157.245.220.201:3002/api/presupuestos/importar-presupuesto/`, {
          method: "POST",
          body: formData,

        });

        if (response.ok) {
          const data = await response.json();
          this.statusMessage = "Los datos se han importado satisfactoriamente";
        } else {
          this.statusMessage = "Error al subir el archivo.";
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        this.statusMessage = "Error al subir el archivo.";
        console.error("Error:", error);
      }
    },
    async getProject() {
      try {
        // Realiza una solicitud a tu API para obtener la lista de proyectos
        const response = await fetch('http://157.245.220.201:3002/api/proyectos');
        this.tempprojects = await response.json(); // Actualiza la lista de proyectos
        this.projects = this.tempprojects.body;
        console.log(this.projects.body);
      } catch (error) {
        console.error('Error al obtener los proyectos: ', error);
      }
    },
  }
};
</script>
  