import { render, staticRenderFns } from "./EtapasyActividadesPresupuestoView.vue?vue&type=template&id=3930b06c&"
import script from "./EtapasyActividadesPresupuestoView.vue?vue&type=script&lang=js&"
export * from "./EtapasyActividadesPresupuestoView.vue?vue&type=script&lang=js&"
import style0 from "./EtapasyActividadesPresupuestoView.vue?vue&type=style&index=0&id=3930b06c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports