<template>
    <div class="container mt-4">
      <h2 class="mb-4">Detalle de Proyecto</h2>
      <b-form @submit.prevent="submitForm">
        <b-form-group label="Proyecto:" label-for="proyecto">
          <b-form-input v-model="proyecto" id="proyecto" required></b-form-input>
        </b-form-group>
        <b-form-group label="Descripción:" label-for="descripcion">
          <b-form-textarea v-model="descripcion" id="descripcion" rows="3" required></b-form-textarea>
        </b-form-group>
        <b-form-group label="Activo:">
          <b-form-checkbox v-model="activo" id="activo">Activo</b-form-checkbox>
        </b-form-group>
        <b-button type="submit" variant="primary">Guardar</b-button>
      </b-form>
    </div>
  </template>
  
  <script>
import Vue from 'vue';
//import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

  export default {
    data() {
      return {
        idproyecto: 0,
        proyecto: '',
        descripcion: '',
        activo: false,
        idusuario: 1,
        idcliente: 1,
      };
    },
    methods: {
      async submitForm() {
        const formData = {
          id: this.idproyecto,
          proyecto: this.proyecto,
          descripcion: this.descripcion,
          activo: this.activo,
          idpropietario: this.idusuario,
          idcliente: this.idcliente,
          creadopor: this.idusuario,
          modificadopor: this.idusuario,
        };
  
        try {
          const response = await fetch('http://157.245.220.201:3002/api/proyectos', {
            method: 'PUT', // Cambiar según la operación que necesites
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
  
          if (response.ok) {
            console.log('Proyecto guardado exitosamente');
          } else {
            console.error('Error al guardar el proyecto');
          }
        } catch (error) {
          console.error('Error de conexión:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: auto;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  </style>
  