<template>
    <div class="container-fluid">
      <div class="edit-msg" v-html="editMsg"></div>
      <wj-flex-grid :itemsSource="gridData" :initialized="initializeGrid">
        <wj-flex-grid-column binding="id" header="ID" :width="50" :isReadOnly="true" />
        <wj-flex-grid-column binding="elemento" header="Elemento" :isReadOnly="true" :isRequired=true :dataMap="data.elemento" />
        <wj-flex-grid-column binding="descripcion" header="Descripción" :isReadOnly="true" :isRequired=true :dataMap="data.descripcion" />
        <wj-flex-grid-column binding="descripciongen" header="Descripción Genérica" :isReadOnly="true" :isRequired=true :dataMap="data.descripcionge" />
      </wj-flex-grid>
    </div>
  </template>
  
  <script lang="js">
  import "@grapecity/wijmo.styles/wijmo.css";
  import 'bootstrap/dist/css/bootstrap.css';
  import Vue from "vue";
  import "@grapecity/wijmo.vue2.grid";
  import * as wjcCore from '@grapecity/wijmo';
  import * as wjcGrid from '@grapecity/wijmo.grid';
  import * as wjcInput from '@grapecity/wijmo.input';
  
  export default {
    //name: 'EditableGridEtapasyActividadesPresupuesto',
    data() {
      return {
        tempgridData: null,
        gridData: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
        editMsg: '&nbsp;',
        //countries: ['US', 'Germany', 'UK', 'Japan', 'Italy', 'Greece'],
        data: [],
      };
    },
    async mounted() {
      const customStyle = document.createElement('style');
      customStyle.innerHTML = `
        .wj-cell.wj-alt {
          height: auto !important;
          /* Otros estilos si es necesario */
        }
      `;
      document.head.appendChild(customStyle);
      try {
        //await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
        await this.fetchData();
      } catch (error) {
        console.error('Error al obtener los proyectos: ', error);
      }
    },
  
    methods: {
      initializeGrid(flex) {
        //this.data = this.fetchData();
        flex.beginningEdit.addHandler((s, e) => {
          this.editMsg = '&nbsp;';
          let col = s.columns[e.col];
          if (col.binding != 'elemento') {
            let item = s.rows[e.row].dataItem;
            if (item.overdue) { // prevent editing overdue items
              e.cancel = true;
              this.editMsg = 'Elemento no puede ser editada';
            }
          }
        }),
        flex.cellEditEnding.addHandler((s, e) => {
          this.editMsg = '&nbsp;';
          let col = s.columns[e.col];
          if (col.binding == 'descripcion' || col.binding == 'descripciongen') {
            let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, col.format);
            if (!wjcCore.isNumber(value) || value < 0) { // prevent negative sales/expenses
              e.cancel = true;
              this.editMsg = 'Por favor ingresa una descripción valida';
            }
          }
        })
      },
  
      async fetchData() {
        try {
  
          // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
          const response = await fetch(`http://157.245.220.201:3002/api/elementos`);
  
          this.tempgridData = await response.json();
          this.gridData = this.tempgridData.body;
          console.log(this.tempgridData);
          console.log(this.gridData);
        } catch (error) {
          console.error('Error al obtener los datos de la tabla: ', error);
        }
      },
        // Función para obtener el texto del tooltip si es necesario
        getTooltipText(text) {
        const maxTooltipLength = 50; // Ajusta la longitud máxima para mostrar el tooltip
        if (text && text.length > maxTooltipLength) {
          return text;
        }
        return '';
      },
      async saveChanges() {
        try {
          // Realiza una solicitud a tu API para guardar los cambios en los datos
          const response = await fetch('http://157.245.220.201:3002/api/guardar-lista-cambios', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.gridData),
          });
  
          if (response.ok) {
            console.log('Cambios guardados exitosamente.');
          } else {
            console.error('Error al guardar los cambios.');
          }
        } catch (error) {
          console.error('Error al realizar la solicitud: ', error);
        }
      },    
    }
  };
  </script>
  
  <style>
  /* Importa el archivo de estilos personalizados */
  @import '@/assets/styles/custom-styles.css';
  
  .wj-flexgrid {
    /* Agrega estilos específicos si es necesario */
    max-height: 100%;
    /*margin-bottom: 12px;*/
  }
  
  .edit-msg {
    font-style: italic;
    text-align: center;
    /*margin-bottom: 10px;*/
    opacity: .5;
  }
  
  body {
    margin-bottom: 24px;
  }
  
  /* Estilos para el tooltip */
  .cell-tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>