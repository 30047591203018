<template>
  <div class="container-fluid">
    <label for="projectSelect">Seleccionar proyecto:</label>
    <select class="form-select" aria-label="Default select example" v-model="selectedProject" @change="fetchData" id="projectSelect">
      <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.proyecto }}</option>
    </select>
    <div class="edit-msg" v-html="editMsg"></div>
    <wj-flex-grid :itemsSource="gridData" :initialized="initializeGrid">
      <wj-flex-grid-column binding="fr" header="F" :isReadOnly="true" :isRequired=true :dataMap="data.fr" />
      <wj-flex-grid-column binding="frente" header="Frente" :isReadOnly="true" :isRequired=true :dataMap="data.frente" />
      <wj-flex-grid-column binding="ni" header="N" :isReadOnly="true" :isRequired=true :dataMap="data.ni" />
      <wj-flex-grid-column binding="dpgen" header="DPGEN" :isReadOnly="true" :isRequired=true :dataMap="data.dpgen" />
      <wj-flex-grid-column binding="partida" header="Partida" :isReadOnly="true" :isRequired=true :dataMap="data.partida" />
      <wj-flex-grid-column binding="importe" header="Importe" :isReadOnly="true" :isRequired=true :dataMap="data.importe" format="n2" />
      <wj-flex-grid-column binding="concat1" header="Concat1" :isReadOnly="true" :isRequired=true :dataMap="data.concat1" />
      <wj-flex-grid-column binding="etapa" header="Etapa" :isReadOnly="false" format="n2" />
      <wj-flex-grid-column binding="secprog" header="Sec Prog" :isReadOnly="false" format="n2" />
      <wj-flex-grid-column binding="nombreprog" header="Nombre Prog" :isReadOnly="false" />
      <wj-flex-grid-column binding="actividad" header="actividad" :isReadOnly="false" :isRequired=true :dataMap="data.actividad" />
    </wj-flex-grid>
    <button @click="saveChanges">Guardar Cambios</button>
  </div>
</template>

<script lang="js">
  //      <wj-flex-grid-column binding="id" header="ID" :width="50" :isReadOnly="true" />

  import "@grapecity/wijmo.styles/wijmo.css";
  import 'bootstrap/dist/css/bootstrap.css';
  import Vue from "vue";
  import "@grapecity/wijmo.vue2.grid";
  import * as wjcCore from '@grapecity/wijmo';
  import * as wjcGrid from '@grapecity/wijmo.grid';
  import * as wjcInput from '@grapecity/wijmo.input';

  /*
  <!-- Agrega la directiva v-if para mostrar el tooltip si el texto es largo -->
          <template v-if="getTooltipText(dataItem.partida) !== dataItem.partida">
            <div class="cell-tooltip" v-b-tooltip.hover.right="getTooltipText(dataItem.partida)">
              {{ dataItem.partida }}
            </div>
          </template>
          <!-- Si no es necesario el tooltip, solo muestra el texto -->
          <template v-else><div>{{ dataItem.partida }}</div></template>

  <!-- Agrega la directiva v-if para mostrar el tooltip si el texto es largo -->
          <template v-if="getTooltipText(dataItem.nombreprog) !== dataItem.nombreprog">
            <div class="cell-tooltip" v-b-tooltip.hover.right="getTooltipText(dataItem.nombreprog)">
              {{ dataItem.nombreprog }}
            </div>
          </template>
          <!-- Si no es necesario el tooltip, solo muestra el texto -->
          <template v-else><div>{{ dataItem.nombreprog }}</div></template>
  */        
  export default {
    //name: 'EditableGridEtapasyActividadesPresupuesto',
    data() {
      return {
        selectedProject: null,
        tempprojects: null,
        projects: null, //[], // Debes llenar esta lista con los proyectos disponibles desde tu API
        tempgridData: null,
        gridData: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
        editMsg: '&nbsp;',
        //countries: ['US', 'Germany', 'UK', 'Japan', 'Italy', 'Greece'],
        data: [],
      };
    },
    async mounted() {
      const customStyle = document.createElement('style');
      customStyle.innerHTML = `
        .wj-cell.wj-alt {
          height: auto !important;
          /* Otros estilos si es necesario */
        }
      `;
      document.head.appendChild(customStyle);
      try {
        await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
        await this.fetchData();
      } catch (error) {
        console.error('Error al obtener los proyectos: ', error);
      }
    },

    methods: {
      initializeGrid(flex) {
        //this.data = this.fetchData();
        flex.beginningEdit.addHandler((s, e) => {
          this.editMsg = '&nbsp;';
          let col = s.columns[e.col];
          if (col.binding != 'partida') {
            let item = s.rows[e.row].dataItem;
            if (item.overdue) { // prevent editing overdue items
              e.cancel = true;
              this.editMsg = 'Partida no puede ser editada';
            }
          }
        }),
        flex.cellEditEnding.addHandler((s, e) => {
          this.editMsg = '&nbsp;';
          let col = s.columns[e.col];
          if (col.binding == 'etapa' || col.binding == 'secprog') {
            let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, col.format);
            if (!wjcCore.isNumber(value) || value < 0) { // prevent negative sales/expenses
              e.cancel = true;
              this.editMsg = 'Por favor ingresa un número positivo';
            }
          }
        })
      },

      async fetchProjects() {
        try {
          // Realiza una solicitud a tu API para obtener la lista de proyectos
          const response = await fetch('http://157.245.220.201:3002/api/proyectos');
          this.tempprojects = await response.json(); // Actualiza la lista de proyectos
          this.projects = this.tempprojects.body;
          console.log(this.projects.body);
        } catch (error) {
          console.error('Error al obtener los proyectos: ', error);
        }
      },
      async fetchData() {
        try {
          if (!this.selectedProject) {
            // Si no se ha seleccionado ningún proyecto, no realizar la solicitud
            return;
          }

          // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
          const response = await fetch(`http://157.245.220.201:3002/api/presupuestos/?idproyecto=${this.selectedProject}`);

          //const response = await fetch(`http://157.245.220.201:3002:5000/api/presupuestos/${this.selectedProject}`);
          //const response = await fetch(`http://157.245.220.201:3002:5000/api/presupuestos`);
          this.tempgridData = await response.json();
          this.gridData = this.tempgridData.body;
          console.log(this.tempgridData);
          console.log(this.gridData);
        } catch (error) {
          console.error('Error al obtener los datos de la tabla: ', error);
        }
      },
        // Función para obtener el texto del tooltip si es necesario
        getTooltipText(text) {
        const maxTooltipLength = 50; // Ajusta la longitud máxima para mostrar el tooltip
        if (text && text.length > maxTooltipLength) {
          return text;
        }
        return '';
      },
      async saveChanges() {
        try {
          // Realiza una solicitud a tu API para guardar los cambios en los datos
          const response = await fetch('http://157.245.220.201:3002/api/guardar-lista-cambios', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.gridData),
          });

          if (response.ok) {
            console.log('Cambios guardados exitosamente.');
          } else {
            console.error('Error al guardar los cambios.');
          }
        } catch (error) {
          console.error('Error al realizar la solicitud: ', error);
        }
      },    
    }
  };
</script>

<style>
  /* Importa el archivo de estilos personalizados */
  @import '@/assets/styles/custom-styles.css';

  .wj-flexgrid {
    /* Agrega estilos específicos si es necesario */
    max-height: 100%;
    /*margin-bottom: 12px;*/
  }

  .edit-msg {
    font-style: italic;
    text-align: center;
    /*margin-bottom: 10px;*/
    opacity: .5;
  }

  body {
    margin-bottom: 24px;
  }

  /* Estilos para el tooltip */
  .cell-tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

