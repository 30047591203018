import './registerServiceWorker'
import router from './router'
import * as wijmo from '@grapecity/wijmo'
//import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import BootstrapVue from 'bootstrap-vue'
//bipracticoerp.com
//wijmo.setLicenseKey("bpea,318918976181235#B0MZHOiI7ckJye0ICbuFkI1pjIEJCLi4TPB9WTGFmMkRlTzVHSz3COvY4VzUWOj3SavtETGR7bpdTS4EkV8NUSX5kamhlVKhXRTRzMEp4Z4sCRzJFSyMGNrglNFhWbnlFaqx6dBBHbz4kZ784SU5kNxlDWSpVeDZTOVlGbXNDSqR6cwADVHpVZVBDbTFlS9IHTtpVYWR5aXRHbslDdilEbtlzdudGUNZjWzh7QPBVV8knS9FjcQFzNqFWcFVGVvEUZJtGSBZmdylEMtVVVSlGSVZUa4ZDaFZFW7FWZXNmUEFnQKFFbxFTMadXNsJkVSZET7l4cqZEZD3mTa5kUKpkTlNUb6d4SBdkcjlTTE9UcDpUU8EVRDVDehdjSE3WVVZVWiFlTklTTytkR6IUZrVmVDZHN4p7QhhDZvd4K7syQYhlMs5EO0tkdXdzNjxGN6JEUwMFemZnVkJ5c75GbCBFOad7b8ATavEjM9Qzdid4QiojITJCLiMUOCNUQxYzNiojIIJCLzkzN7QTN6QjM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIyMygjMyEDIwMDOwMjMwIjI0ICdyNkIsISbvNmLwJXZvNWa4NWYyBXaiJiOiMXbEJCLiY5QgUGZgwkUTBybjlGdjFmcQJiOiEmTDJCLiUzMyEDOxYzN9gTM9gTMzIiOiQWSiwSfdtlOicGbmJCLiIjdzIDMyIiOiIXZ6JCLhNLb");
//159.65.166.154
wijmo.setLicenseKey("318918976181235#B0YIJNHZisnOiwmbBJye0ICRiwiI34zZGZ7QwcTZwZ5YLR4SSplU6YDVNJUeERFdykleplVYRhmanV7RMRWSOBTYGh5RThHSTdkQmhmVZFjbIhUahl7b4UzVytCRpNHN5JkapZ7ZQhnNSpmYx56Z5QUapZFRaNWSSZXTX9WUXpmYKJVSRVjM4cHSMNnM7EmQh3GO5N7cyQUNVlGMtRVd7tGM9ckR6h7QaFlTWt4S7JDb4cDZEZVONVjeFh7RydUbZFVUpR5RmFHRChVRVJ4cwUGNBp7YK3yK7EnbRVmSPRXaZRTbVJlSPlDa4InS5ZUR9IXN8UlTsJ7bY9GckFWTyUGen5EVFZXT7JkWZB5VS3mYSdGZjl6YQtEeYR6bmpHa5cGRPlTZ6t4YvEDTTBVZ4RjZ0lFNDRlaV3UZn5WO7M4cIt6TuV6QURWRpZGSTB7M0B7VMtWMrMVQGtiUuFkV7YDaBh6LB3ia8cXepZmI0IyUiwiIFRzM7QDO9YjI0ICSiwCMxcjNzkzN9kTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiETMxIjMxASOxITMzIDMyIiOiQncDJCLiQTNx8iN6EjL5YjL9UTMiojIz5GRiwiIWNEIlRGIMJ5Ug26YpR7YhJHUiojIh94QiwiI5MjMxgTM6cTO8ETO8EzMiojIklkIs4XXbpjInxmZiwiIyY7MyAjMiojIyVmdiwSZzxMYwp");
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import App from './App.vue'
import store from './store' // Importa el almacén VueX

Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import 'bootstrap/dist/js/bootstrap'
