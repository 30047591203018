<template>
    <div class="bienvenido">
      <LoginView @loggedIn="redirectToHome" v-if="!isLoggedIn" />
    </div>
  </template>
  
  <script>
  import LoginView from "@/views/seguridad/LoginView.vue";
  
  export default {
    components: {
      LoginView,
    },
    data() {
      return {
        isLoggedIn: false,
      };
    },
    methods: {
      redirectToHome() {
        this.isLoggedIn = true;
        this.$router.push('/inicio');
      },
    },
  };
  </script>
  
  <style scoped>
  .bienvenido {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
  }
  </style>
  