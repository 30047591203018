<template>
  <div style="background-color: rgb(180, 200, 230);">
    <h1 style="background-color: rgb(155, 155, 155); color: white;">Consulta de Presupuesto {{ this.projectDescripcion }}
    </h1>
    <!-- Contenedor principal  style="width: 100%; height: 100vh;"-->
    <div class="container-fluid">
      <!-- Botón de colapso -->
      <div class="text-right mb-2"> <!-- Alinea el botón a la derecha y agrega espacio inferior -->
        <button @click="toggleCollapse">{{ collapsed ? 'Expandir' : 'Colapsar' }}</button>
      </div>
      <div class="col-sm-3">
        <!-- Contenedor del PivotPanel (solo se renderiza si selectedProject tiene un valor) -->
        <div id="mi-panel" :class="{ 'collapsed': collapsed }" ref="pivotPanel" v-show="!collapsed">
          <!-- Contenido del PivotPanel -->
        </div>
      </div>

      <div class="row">
          <!-- Contenedor del PivotGrid style="width: 100%; height: 100%;"-->
          <div ref="pivotGrid" style="width: 100%; height: 100%; font-family: Arial, sans-serif; font-size: 12px;"></div>
        </div>
    </div>
  </div>
</template>

<script>

  import '@grapecity/wijmo.styles/wijmo.css';
  import 'bootstrap/dist/css/bootstrap.css';
  import Vue from 'vue';
  import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
  import '@grapecity/wijmo.vue2.olap';
  import * as wjcOlap from '@grapecity/wijmo.olap';
  // Definir la constante customTemplate aquí
  const apiCache = {};
  const customTemplate = `
  <div class="root">  
    <div class="field-list-label">  
      <label wj-part="g-flds">Elija los campos para agregar al informe:</label>  
    </div>  
    <div class="field-list pad">  
      <div wj-part="d-fields"></div>  
    </div>  
    <div class="drag-areas-label">  
      <label wj-part="g-drag"></label>  
    </div>  
    <div class="filter-list pad">  
      <label>  
        <span class="wj-glyph wj-glyph-filter"></span>   
        <span wj-part="g-flt"></span>  
      </label>  
      <div wj-part="d-filters"></div>  
    </div>  
    <div class="column-list pad bdr-left">  
      <label>  
        <span class="wj-glyph">⫴</span>   
        <span wj-part="g-cols"></span>  
      </label>  
      <div wj-part="d-cols"></div>  
    </div>  
    <div class="row-list pad bdr-top">  
      <label>  
        <span class="wj-glyph">≡</span>   
        <span wj-part="g-rows"></span>  
      </label>  
      <div wj-part="d-rows"></div>  
    </div>  
    <div class="values-list pad bdr-left bdr-top">  
      <label>  
        <span class="wj-glyph">Σ</span>   
        <span wj-part="g-vals"></span>  
      </label>  
      <div wj-part="d-vals"></div>  
    </div>  
    <div wj-part="d-prog" class="progress-bar"></div>  
    <div class="control-area">  
      <label>  
        <input wj-part="chk-defer" type="checkbox">   
        <span wj-part="g-defer">Detener actualizaciones</span>  
      </label>  
      <button wj-part="btn-update" class="wj-btn wj-state-disabled" type="button" disabled>
        Actualizar
      </button>  
    </div>  
  </div>`;

  export default {
    data() {
      return {
        selectedProject: null,
        projects: [],
        ngPanel: null,
        collapsed: true, // Inicialmente, el contenido está colapsado
      };
    },
    async created() {
      if (this.projectId) {
        this.selectedProject = this.projectId
        wjcOlap.PivotPanel.controlTemplate = customTemplate;
        await this.loadProjectDataWithCache();
      }
      else {
        await this.fetchProjectsWithCache();
        wjcOlap.PivotPanel.controlTemplate = customTemplate;
      }

      // Configurar el evento formatItem después de cargar los datos
      console.log("9. pasa por aqui...");
      if (this.pivotGrid) {
      console.log("9.1. pasa por aqui...");
      this.pivotGrid.formatItem.addHandler((s, e) => {
        if (e.panel == s.columnHeaders) {
          const columnName = s.columns[e.col].binding; // Obtener el nombre de la columna
          console.log("9.2. pasa por aqui...", columnName);
          console.log("9.3. pasa por aqui... row: ", s.columns[e.col]);
          if (columnName === 'Frente:0;') {
            //
          } else if (columnName === 'CantUnitIns:0;' || columnName === 'N:0;') {
            console.log("9.2.1. pasa por aqui... valor: ", columnName);
            //console.log(e.row.dataItem.subtotal);
            //const groupText = e.text.replace("Subtotal", e.row.dataItem.groupText);
            //e.cell.innerHTML = groupText;
            e.cell.style.backgroundColor = '#FFD700'; // Color naranja tenue
            e.cell.style.color = 'black'; // Color de texto negro
            
            //{color: highlightDownloads? (cell.item.downloads>10000 ?'green':'red'):''
          }
        }
        //const colName = s.columns[e.col].binding;
        //if (colName === "Frente:0;") {
          e.cell.style.backgroundColor = 'gray';
          //this.pivotGrid.frozenRows = 1;
          //s.item[0].background = 'read';
        //}
        
        //console.log("9.2.2.0. pasa por aqui... valor: ", e.cell);
        //e.cell.item.frente = 'green';
        console.log("9.2.2.1. pasa por aqui... valor: ", s.cells);
        console.log("9.2.2.2. pasa por aqui... valor: ", e.panel.cellType);
        //console.log("9.2.2.3. pasa por aqui... valor: ", wijmo.grid.CellType.Cell);

        /* 
        //} else if (e.panel != s.columnHeaders) {
        //  const cellValue = s.getCellData(e.row, e.col, false);
        //  console.log(cellValue);
        //  hdrTips.setTooltip(e.cell, '<span class="col-header">' + cellValue + '</span>');
        }
        if (e.panel == s.rowHeaders) { // Verificar si el panel es de filas
        const columnName = s.columns[e.col].binding;
        console.log("9.2. pasa por aqui... row: ", columnName);
        console.log("9.3. pasa por aqui... row: ", s.columns[e.col]);

          if (e.cellValue === 'Subtotal') {
              const groupText = e.text.replace("subtotal", e.row.dataItem.groupText);
              e.cell.innerHTML = groupText;
              e.cell.style.backgroundColor = '#FFD700';
              e.cell.style.color = 'black';
          }*/
        //}
        //console.log("9.2.2. colapsoallcells");
      //this.collapseAllCells();
        //console.log(s);
        /*if (e.panel == s.cells && e.cellType === wijmo.grid.CellType.Cell) { // Verificar si es el panel de celdas y si es una celda normal
          const rowItem = s.rows[e.row].dataItem; // Obtener el elemento de datos de la fila

          // Verificar la condición para aplicar el formato
          if (rowItem && (rowItem.dataField === 'CantUnitIns' || rowItem.dataField === 'N')) {
              e.cell.style.backgroundColor = '#FFD700'; // Color naranja tenue
              e.cell.style.color = 'black'; // Color de texto negro
          }

          if (e.cellValue === 'Subtotal') {
              const groupText = e.text.replace("subtotal", rowItem.groupText);
              e.cell.innerHTML = groupText;
              e.cell.style.backgroundColor = '#FFD700';
              e.cell.style.color = 'black';
          }
        }*/
      });
      } else {
        console.error("this.ngPanel is undefined");
      }
      // Agregar el manejador para el evento de actualización
      console.log("10. pasa por aqui...");
      this.pivotGrid.updatingLayout.addHandler(this.handleRefreshing);
      // 
      console.log("11. pasa por aqui...");
      //this.pivotGrid.format-Item.addHandler(this.formatItem);
    },
    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      }
    },
    watch: {
      selectedProject(newValue, oldValue) {
        // Tu lógica aquí
      },
    },
    methods: {
      
      toggleCollapse() {
        this.collapsed = !this.collapsed; // Cambiar el estado de colapso
      },

      async fetchProjectsWithCache() {
        try {        
          const cacheKey = 'projectsCache';
          if (apiCache[cacheKey]) {
            this.projects = apiCache[cacheKey];
            console.log('Se cargo desde cache');
          } else {
            try {
              const response = await fetch('http://157.245.220.201:3002/api/proyectos');
              console.log("2. fetchProjects: pasa por aquí");
              if (response.ok) {
                const data = await response.json();
                this.projects = data.body;
                apiCache[cacheKey] = data.body; // Almacenar en caché
                console.log('Se cargo desde api.');
              } else {
                console.log('No se pudo cargar la lista de proyectos');
              }
            } catch (error) {
              console.error('Error al cargar la lista de proyectos: ', error);
            }
          }
        } catch (error) {
          console.error('Error al obtener el proyecto:', error);
        }
      },

      async loadProjectDataWithCache() {
        try {
          if (!this.selectedProject) {
            return;
          }

          const cacheKey = `projectDataCache_${this.selectedProject}`;
          if (apiCache[cacheKey]) {
            this.initializePivotGrid(apiCache[cacheKey]);
            console.log('Se cargo desde cache');
          } else {
            try {
              const response = await fetch(`http://157.245.220.201:3002/api/presupuestos/?proyecto=${this.selectedProject}`);
              console.log("3. loadProjectData: pasa por aquí");
              if (response.ok) {
                console.log("3.0. loadProjectData: pasa por aquí");
                const projectData = await response.json();
                console.log("3.1. loadProjectData: pasa por aquí");
                this.initializePivotGrid(projectData.body);
                apiCache[cacheKey] = projectData.body; // Almacenar en caché
                console.log('Se cargo desde api.');
              } else {
                console.log('No se pudo cargar el proyecto. Estado: ', response.status);
              }
            } catch (error) {
              console.error('Error al cargar el proyecto: ', error);
            }
          }
        } catch (error) {
          console.error('Error en loadProjectDataWithCache:', error);
        }        
      },

      initializePivotGrid(data) {
        // Crear un PivotEngine con los datos obtenidos de la API
        const ngPanel = new wjcOlap.PivotEngine({
          itemsSource: data,
          autoGenerateFields: false,
          fields: [
            { binding: 'presupuesto', header: 'Presupuesto', width: 70 },
            { binding: 'fr', header: 'Fr', width: 60 },
            { binding: 'frente', header: 'Frente', width: 100 },
            { binding: 'ni', header: 'N', width: 55 },
            { binding: 'dpgen', header: 'DPGen', width: 80 },
            { binding: 'partida', header: 'Partida', width: 120 },
            { binding: 'tarjeta', header: 'Tarjeta', width: 120 },
            { binding: 'fi', header: 'FI', width: 120 },
            { binding: 'di', header: 'Insumo', width: 120 },
            { binding: 'cantunitins', header: 'CantUnitIns', format: 'n3' },
            { binding: 'pi', header: 'PI', format: 'c2', aggregate: 'Avg' },
            { binding: 'put', header: 'P.U.T.', format: 'c2' },
            { binding: 'pup', header: 'P.U.P.', format: 'c2' },
            { binding: 'importe', header: 'Importe', format: 'c2' },
          ],
          filterFields: ['Presupuesto'],
          valueFields: ['CantUnitIns', 'PI', 'P.U.T.', 'P.U.P.', 'Importe'],
          rowFields: ['Frente', 'N', 'DPGen', 'Partida', 'Tarjeta', 'FI', 'Insumo'],
          showRowTotals: 'Subtotals',
          showColumnTotals: 'Subtotals',      
        });

        console.log("4. initializePivotGrid: pasa por aquí");
        // Configurar la propiedad collapsedHeaders para que las columnas se carguen colapsadas por defecto

        // Obtener el contenedor del PivotGrid por su ref
        console.log("4.1. initializePivotGrid: pasa por aquí");

        // Obtener el contenedor del PivotGrid por su ref
        console.log("4.2. initializePivotGrid: pasa por aquí");

        // Asegurarse de que el contenedor exista antes de inicializar el PivotGrid
        console.log("4.3. initializePivotGrid: pasa por aquí");
        if (this.$refs.pivotGrid) {
          // Limpiar cualquier contenido anterior en el contenedor
          this.$refs.pivotGrid.innerHTML = '';

          // Crear y mostrar el PivotGrid en el contenedor
          console.log("4.3.1. initializePivotGrid: pasa por aquí");
          this.pivotGrid = new wjcOlap.PivotGrid(this.$refs.pivotGrid, {
            itemsSource: ngPanel.pivotView,
            autoScroll: true,// Puedes usar 'Vertical' o 'Both' según tus necesidades
            errorTip: true,
            showErrors: true,
          });        
        }

        // Crear un PivotPanel y conectarlo al mismo PivotEngine
        console.log("4.4. initializePivotGrid: pasa por aquí");
        new wjcOlap.PivotPanel(this.$refs.pivotPanel, {
          engine: ngPanel,
        });

        console.log("4.5. initializePivotGrid: pasa por aquí");
        this.ngPanel = ngPanel;
        this.pivotGrid.scrollPositionX = 2; // Ajusta la posición horizontal del scroll hacia la derecha

        // Después de inicializar el PivotGrid
        this.pivotGrid.resizingColumn.addHandler(() => {
          this.pivotGrid.autoSizeColumns();
        });

        // Espera a que el PivotGrid se haya renderizado completamente
        this.$nextTick(() => {
          if (this.pivotGrid) {
            // Colapsa los renglones al nivel deseado (por ejemplo, nivel 0)
            console.log("4.6. initializePivotGrid: pasa por aquí");
          }
        });
      },
      handleRefreshing: function (sender, args) {
        if (sender.engine) {
            // Collapse rows to level 1.
            sender.collapseRowsToLevel(1);
            console.log('pasa a handleRefreshing');
            // Remove the handler to avoid recursion.
            sender.updatingLayout.removeHandler(this.handleRefreshing);
        }
      },
      /*formatItem: (sender, args) => {
        if (args.panel != sender.columnHeaders) {
          const cellValue = sender.getCellData(args.row, args.col, false);
          console.log(cellValue);
          hdrTips.setTooltip(args.cell, '<span class="col-header">' + cellValue + '</span>');
        }
      },*/
    },
  };
</script>

<style>
.collapsed {
  display: none;
  /* Oculta el contenido cuando está colapsado */
}

#mi-panel {
  min-height: 0;
  max-width: 300px;
  margin: 12px auto;
  display: block;
  background: #eee;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: 100%;
}

#mi-panel .wj-flexgrid {
  min-height: 4em;
  background: inherit;
}

.container-fluid {
  width: 100%;
  /* Ocupa todo el ancho disponible */
  max-width: 100%;
  /* No excede el ancho disponible */
  height: 100%;
  /* Ajusta la altura según el contenido */
  overflow-x: auto;
  /* Habilita el scrollbar horizontal si es necesario */
  /* Otros estilos que desees aplicar */
}

.wj-pivotpanel .root {
  display: -ms-grid;
  display: grid;
  color: black;
}

.wj-pivotpanel .field-list-label {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/span 2;
  -ms-grid-row: 1;
  grid-row: 1;
}

.wj-pivotpanel .field-list {
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 1/span 3;
  -ms-grid-row: 2;
  grid-row: 2;
}

.wj-pivotpanel .drag-areas-label {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/span 2;
  -ms-grid-row: 3;
  grid-row: 3;
}

.wj-pivotpanel .filter-list {
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 1/span 3;
  -ms-grid-row: 4;
  grid-row: 4;
}

.wj-pivotpanel .column-list {
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 1/span 3;
  -ms-grid-row: 5;
  grid-row: 5;
}

.wj-pivotpanel .row-list {
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 1/span 3;
  -ms-grid-row: 6;
  grid-row: 6;
}

.wj-pivotpanel .values-list {
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 1/span 3;
  -ms-grid-row: 7;
  grid-row: 7;
}

.wj-pivotpanel .progress-bar {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/span 2;
  -ms-grid-row: 8;
  grid-row: 8;
  width: 0px;
  height: 3px;
}

.wj-pivotpanel .control-area {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/span 2;
  -ms-grid-row: 8;
  grid-row: 8;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
}

.wj-pivotpanel .control-area button {
  -ms-grid-column: 2;
  grid-column: 2;
  align-self: end;
  -ms-grid-column-align: end;
}

.wj-pivotpanel .pad {
  padding: 6px;
}

.wj-pivotpanel .bdr-left {
  border-left: 1px solid rgba(0, 0, 0, .2);
}

.wj-pivotpanel .bdr-top {
  border-top: 1px solid rgba(0, 0, 0, .2);
}

body {
  margin-bottom: 24pt;
}

.wj-pivotpanel .wj-flexgrid .wj-cell.wj-group {
  background: inherit;
  color: black;
}

.importe-header {
  background-color: #FFD700;
  /* Color naranja tenue */
  color: black;
  /* Color de texto negro */
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 9999;
}
</style>
