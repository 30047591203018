// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projectId: null, // Variable global para el ID del proyecto
    projectDescription: null // Variable global para la descripción del proyecto
  },
  mutations: {
    setProjectId(state, id) {
      state.projectId = id;
    },
    setProjectDescription(state, description) {
      state.projectDescription = description;
    }
  },
  actions: {
    updateProjectInfo({ commit }, { id, description }) {
      commit('setProjectId', id);
      commit('setProjectDescription', description);
    }
  },
  getters: {
    getProjectId: state => state.projectId,
    getProjectDescription: state => state.projectDescription
  }
});
