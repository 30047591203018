import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import InicioView from '../views/generales/HelloWorld.vue'
import Bienvenido from '../views/seguridad/BienvenidoView.vue'
import EligeProyectoView from '../views/proyectos/EligeProyectoView.vue'
import DetalleProyectoView from '../views/proyectos/DetalleProyectoView.vue'
import PanelPresupuestoView from '../views/borradores/borradorConsultaPresupuestoView.vue'
import ConsultaPresupuestoView from '../views/presupuestos/ConsultaPresupuestoView.vue'
import NConsultaPresupuestoView from '../views/presupuestos/NConsultaPresupuestoView.vue'
import ElementosPresupuestoView from '../views/catalogos/ElementosPresupuestoView.vue'
import EtapasyActividadesPresupuestoView from '../views/actividades/EtapasyActividadesPresupuestoView.vue'
import ImportarPresupuestoView from '../views/presupuestos/ImportarPresupuestoView.vue'
import OpcionesPresupuestoView from '../views/configuraciones/OpcionesPresupuestoView.vue'
import AboutView from '../views/generales/AboutView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  /*{
    path: '/',
    name: 'inicio',
    component: InicioView
  },*/
  {
    path: '/',
    name: 'Bienvenido',
    component: Bienvenido//() => import('@/views/seguridad/BienvenidoView.vue'), // Crea este componente si no lo tienes
  }, 
  {
    path: '/inicio',
    name: 'Inicio',
    component: InicioView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/proyecto',
    name: 'EligeProyecto',
    component: EligeProyectoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/detalleproyecto',
    name: 'detalleproyecto',
    component: DetalleProyectoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/panelpresupuesto',
    name: 'panelpresupuesto',
    component: PanelPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/consultapresupuesto',
    name: 'consultapresupuesto',
    component: ConsultaPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/nconsultapresupuesto',
    name: 'nconsultapresupuesto',
    component: NConsultaPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/elementospresupuesto',
    name: 'elementospresupuesto',
    component: ElementosPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/etapasyactividadespresupuesto',
    name: 'etapasyactividadespresupuesto',
    component: EtapasyActividadesPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/importarpresupuesto',
    name: 'importarpresupuesto',
    component: ImportarPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  },
  {
    path: '/opcionespresupuesto',
    name: 'opcionespresupuesto',
    component: OpcionesPresupuestoView,
    meta: { requiresAuth: true }, // Esta meta para requerir autenticación
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
